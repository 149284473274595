@import './utils';
@import './components';
@import './layout';


.divider {
    height: 50px;
}

.container {
    width: 90%;
    
    @media ($desktop) {
        width: 1024px!important;     
    }
}
.ui__title {
    font-size: 2rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
    text-decoration: underline;
}
.h1 {
    @include h1();
}
.h2 {
    @include h2();
}
input {
    @include input();

}
// .slider {
//     @include slide();

// }
.inptSearch {
    background-image: url('../images/icon_search.svg');
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 0.5rem;
    @media ($tablet) {
        width: 50%;
        margin-top: 2rem;
    }
    @media ($desktop) {
        width: 50%;
        margin-top: 2rem;
    }
}
p {font-size:1.4rem;font-family: monospace;}
#katalog {
  display: grid;
  grid-template-columns: 100px 100px 100px;
  grid-gap: 10px;
  background-color: #fff;
  color: #444;
  width: 30rem;
}
.cat {
    @include cat('../images/dnevnik1.png');
}
.txtReclama {
    @include txtReclama();
}
.cntr {flex-direction: column;
    display: flex;
    align-items: center;
}
.hdr {
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    display: flex;
    @media ($tablet) {
        justify-content: left;
        margin-top: 1rem;
    }
    @media ($desktop) {
        justify-content: left;
        margin-top: 1rem;
    }
}
#head {
    @media ($tablet) {
        display: flex;
        align-items: center;
        justify-content: space-between;
       
    }
    @media ($tablet) {
        display: flex;
        align-items: center;
        justify-content: space-between;
       
    }
    @media ($desktop) {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}
.info {
    width: 20%;
    display: none;
    @media ($tablet) {
        display: block;
    }
    @media ($desktop) {
        display: block;
    }
}
.infoCatalog {
    display: flex;
    flex-direction: column;
    color:#454545;
    font-family: monospace;
    align-items: center;
    margin-top: 1rem;
    cursor: pointer;
}
.glb-animation{
    animation: moving 15s infinite linear;
    transform-origin: center;
}
@keyframes moving {
    100% {transform: rotate(360deg);}
}
.strelki {
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
    cursor: pointer;
    position: relative;
    top: 50%;
}
.line {border-bottom: 3px solid #D9002A!important;width: 30%;}